import React from 'react'

import integrations from '../images/integrations.png'
import screenshot from '../images/screenshot.png'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout darkHeader={true}>
    <div className="bg-gray-900">
      <div className="container">
        <div className="mb-24 pt-24">
          <h1 className="text-center mb-3 text-blue-100 text-shadow-dark font-bold">Drop-in application monitoring.</h1>
          <p className="text-center text-lg text-blue-100 mb-12">
            Performance monitoring, errors, and logs give you full visibility in one place.
          </p>

          <div className="flex justify-center">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5PfnfRaOX-8ouwYVKhJWqIeX1igXXMfurTPKpp87o7ifviQ/viewform?usp=sf_link" className="bg-blue-600 rounded text-white px-5 py-4 hover:opacity-75" data-track-click="home-request-early-access-top">Request early access</a>
          </div>
        </div>
      </div>{/* container */}

      {/* waves */}
      <div>
        <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="transparent" stroke="rgba(255,255,255,0.2)" strokeWidth=".3" strokeDasharray=".3 1" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="transparent" stroke="rgba(141,162,251,0.8)" strokeWidth=".3" strokeDasharray="1 3" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(141,162,251,0.2)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </div>
      {/* waves */}
    </div>

    <div className="bg-white py-32">
      <div className="container md:flex md:items-center">

        <div className="w-full md:w-1/2 mb-8 md:mb-0 text-center">
          <img src={screenshot} alt="tail.dev screenshot" />
        </div>

        <div className="flex flex-col w-full md:w-1/2 px-8 md:pl-24">

          <div className="w-full mb-8">
            <div className="flex items-center mb-4">
              <h3 className="font-bold mb-0">APM</h3>
            </div>
            <p>
              Application Performance Monitoring provides automatic insights into requests, background jobs, database transactions, and more.
            </p>
          </div>

          <div className="w-full mb-8">
            <div className="flex items-center mb-4">
              <h3 className="font-bold mb-0">Errors</h3>
            </div>
            <p>
              Capture and report errors before your users do with stack traces and automatic metadata that provides the full context.
            </p>
          </div>

          <div className="w-full">
            <div className="flex items-center mb-4">
              <h3 className="font-bold mb-0">Logs</h3>
            </div>
            <p>
              Collect, tag, and search application logs to provide complete visibility surrounding exceptions and performance monitoring.
            </p>
          </div>

        </div>
      </div>
    </div>

    <div className="py-20">
      <div className="container">
        <div className="flex items-center justify-center">
          <div className="mb-8">
            <h2 className="font-bold mb-3">Easy setup, batteries included</h2>
            <p className="mb-12">
              Everything <em>just works</em> out of the box.
            </p>

            <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5PfnfRaOX-8ouwYVKhJWqIeX1igXXMfurTPKpp87o7ifviQ/viewform?usp=sf_link" className="inline-block bg-blue-600 text-white font-bold px-6 py-4 rounded shadow hover:bg-blue-500 mr-2 mb-2" data-track-click="home-request-early-access-top">
              I want in <i className="fas fa-chevron-right ml-1" />
            </a>
            <a href="/documentation" className="inline-block border border-blue-600 text-blue-600 font-bold px-6 py-4 rounded shadow hover:border-blue-500 hover:text-blue-500 mb-2">
              Documentation <i className="fas fa-chevron-right ml-1" />
            </a>
          </div>

          <div className="pl-8 text-center hidden sm:block">
            <img src={integrations} alt="Integrations" className="inline-block" style={{width: '100%', maxWidth: '400px'}} />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
